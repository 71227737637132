body {
  margin: 0px;
  padding: 0px;
  background-color: #e7ebee;
}

.App {
  text-align: center;
  font-family: sans-serif;
}

.App-logo {
  height: 40vmin;
  border-radius: 50px;
  pointer-events: none;
}

.logo {
  height: 30vmin;
  border-radius: 50px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.content {
  display: flex;
}

.side {
  width: 30%;
  background-color: black;
  border-right: 3px solid blueviolet;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.videos {
  margin: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
}

.video {
  margin: 15px;
  width: 45%;
  min-width: 300px;
  min-height: 250px;
}

.support {
  position: fixed;
  bottom: 1px;
}